import React, { useRef, useState } from 'react';
import Sidebar from '../components/common/Sidebar';
import AddEstablishmentButton from '../components//establishments/AddEstablishmentButton';
import EstablishmentsList from '../components/establishments/EstablishmentsList';
import api from '../api/axiosConfig';
import './Dashboard.css';

const Establishments = () => {
  const establishmentsListRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);

  const handleEdit = (establishment) => {
    setSelectedEstablishment(establishment);
    setIsEditing(true);
  };

  const handleSaveChanges = async (updatedData) => {
    const confirmUpdate = window.confirm(`¿Estás seguro que quieres editar el establecimiento ${selectedEstablishment.name}?`);
    if (!confirmUpdate) return;

    try {
      const token = localStorage.getItem('authToken');
      await api.put(`/api/establishments/${selectedEstablishment._id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Establecimiento actualizado con éxito');
      establishmentsListRef.current.refreshList();
      setIsEditing(false);
      setSelectedEstablishment(null);
    } catch (error) {
      console.error('Error al actualizar el establecimiento:', error);
      alert('Hubo un problema al actualizar el establecimiento. Por favor, inténtelo de nuevo.');
    }
  };

  const handleDelete = async (establishment) => {
    const confirmDelete = window.confirm(`¿Estás seguro que quieres eliminar el establecimiento ${establishment.name}?`);
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem('authToken');
      await api.delete(`/api/establishments/${establishment._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Establecimiento eliminado con éxito');
      establishmentsListRef.current.refreshList();
    } catch (error) {
      console.error('Error al eliminar el establecimiento:', error);
      alert('Hubo un problema al eliminar el establecimiento. Por favor, inténtelo de nuevo.');
    }
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <h2>Página de Establecimientos</h2>
        <AddEstablishmentButton
          refreshList={() => establishmentsListRef.current.refreshList()}
          initialData={isEditing ? selectedEstablishment : null}
          isEditing={isEditing}
          onSave={handleSaveChanges}
          onCancel={() => setIsEditing(false)}
        />
        <EstablishmentsList ref={establishmentsListRef} onEdit={handleEdit} onDelete={handleDelete} />
      </div>
    </div>
  );
};

export default Establishments;

const provinces = [
    'Buenos Aires',
    'Córdoba',
    'Santa Fe',
    'Mendoza',
    'Tucumán',
    'Entre Ríos',
    'Salta',
    'Misiones',
    'Chaco',
    'Corrientes',
    'Santiago del Estero',
    'San Juan',
    'Jujuy',
    'Río Negro',
    'Neuquén',
    'Formosa',
    'Chubut',
    'San Luis',
    'Catamarca',
    'La Pampa',
    'La Rioja',
    'Santa Cruz',
    'Tierra del Fuego',
  ];
  
  export default provinces;
  
import React from 'react';
import './Dashboard.css';
import Sidebar from '../components/common/Sidebar';

const Dashboard = () => {
  const userName = localStorage.getItem('userName');  // Obtener el nombre del usuario
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <h2 className="welcome-message">Hola {userName}, bienvenido a tu panel de control</h2>
        {/* Aquí puedes agregar más componentes o elementos del dashboard */}
      </div>
    </div>
  );
};

export default Dashboard;

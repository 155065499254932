import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EstablishmentForm from '../forms/EstablishmentForm';
import api from '../../api/axiosConfig';
import './AddEstablishmentButton.css';

const AddEstablishmentButton = ({ refreshList, initialData, isEditing, onSave, onCancel }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (isEditing && initialData) {
      setIsPopupOpen(true);
    }
  }, [isEditing, initialData]);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    onCancel();
  };

  const handleFormSubmit = async (data) => {
    if (isEditing) {
      await onSave(data);
      closePopup();  // Cierra el modal después de la actualización exitosa
    } else {
      try {
        const token = localStorage.getItem('authToken');
        await api.post('/api/establishments', data, {  // Eliminamos la variable 'response'
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert('Establecimiento creado con éxito');
        refreshList();
        closePopup();  // Cierra el modal después de la creación exitosa
      } catch (error) {
        console.error('Error al crear el establecimiento:', error);
        alert('Hubo un problema al crear el establecimiento. Por favor, inténtelo de nuevo.');
      }
    }
  };  

  return (
    <div className="add-establishment-button-container">
      {!isEditing && (
        <button className="add-button" onClick={openPopup}>
          <span className="plus-icon">+</span> Crear Establecimiento
        </button>
      )}

      {isPopupOpen && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-popup" onClick={closePopup}>X</button>
            <EstablishmentForm
              onSubmit={handleFormSubmit}
              initialData={initialData}
              isEditing={isEditing}
            />
          </div>
        </div>
      )}
    </div>
  );
};

AddEstablishmentButton.propTypes = {
  refreshList: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

AddEstablishmentButton.defaultProps = {
  initialData: null,
  isEditing: false,
  onSave: () => {},
  onCancel: () => {},
};

export default AddEstablishmentButton;

import React from 'react';
import PropTypes from 'prop-types'; // Asegúrate de importar PropTypes
import jsPDF from 'jspdf'; // Importamos jsPDF
import 'jspdf-autotable'; // Importamos la extensión para tablas
import './FormDetails.css'; // Asegúrate de tener este archivo CSS

const FormDetails = ({ form, onBack }) => {
  if (!form) {
    return <p>Cargando formulario...</p>;
  }

  // Función para generar el PDF
  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    // Establecemos una fuente consistente para evitar problemas de renderizado
    doc.setFont("helvetica", "normal");

    // Añadimos el título del formulario
    doc.setFontSize(18);
    doc.text(form.title, 10, 10);

    // Añadimos la descripción si existe
    doc.setFontSize(12);
    doc.text(form.description || "Sin descripción", 10, 20);

    // Generar una tabla con los campos y valores del formulario
    const tableData = form.fields.map((field) => [
      field.label || field.name,
      field.value
    ]);

    // Reemplazar caracteres especiales como "≤" o "≥"
    const handleSpecialChars = (text) => {
      return text.replace(/≤/g, '<=').replace(/≥/g, '>=');
    };

    // Aplicar la función para limpiar caracteres especiales
    const cleanedTableData = tableData.map(([label, value]) => [
      handleSpecialChars(label),
      handleSpecialChars(value.toString())
    ]);

    doc.autoTable({
      head: [['Campo', 'Valor']],
      body: cleanedTableData,
      startY: 30,
    });

    // Obtener la fecha de creación del formulario
    const creationDate = new Date(form.createdAt).toLocaleDateString('es-ES');

    // Descargamos el PDF
    doc.save(`${form.title} ${creationDate}.pdf`);
  };

  return (
    <div className="form-details">
      <div className="button-container">
        <button onClick={onBack} className="back-button">Volver</button>
        <button onClick={handleDownloadPDF} className="download-button">Descargar</button>
      </div>
      <h2>{form.title}</h2>
      <p>{form.description || "Sin descripción"}</p>

      <table className="form-fields">
        <thead>
          <tr>
            <th>Campo</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {form.fields.map((field) => (
            <tr key={field._id}>
              <td>{field.label || field.name}</td>
              <td>{field.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Definición de PropTypes para validar las propiedades recibidas
FormDetails.propTypes = {
  form: PropTypes.shape({
    title: PropTypes.string.isRequired, // form.title debe ser una cadena
    description: PropTypes.string, // form.description es opcional
    createdAt: PropTypes.string.isRequired, // createdAt es requerido
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired, // Cada campo debe tener un _id
        label: PropTypes.string, // Opcional, ya que puede usar name en su lugar
        name: PropTypes.string.isRequired, // Cada campo debe tener un name
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) // El valor puede ser string o número
      })
    ).isRequired // form.fields es requerido
  }).isRequired, // form es requerido
  onBack: PropTypes.func.isRequired // onBack debe ser una función
};

export default FormDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/common/Home';
import Login from './screen/Login';
import Signup from './screen/Signup';
import Dashboard from './screen/Dashboard';
import Establishments from './screen/Establishments';
import Establishment from './screen/Establishment';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('authToken');
  return isAuthenticated ? children : <Navigate to="/login" />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>} />
          <Route path="/dashboard/establecimientos" element={
            <PrivateRoute>
              <Establishments />
            </PrivateRoute>} />
          <Route path="/dashboard/establecimiento/:id/*" element={
            <PrivateRoute>
              <Establishment />
            </PrivateRoute>} />
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;

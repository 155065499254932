import React from 'react';
import PropTypes from 'prop-types';
import '../../screen/Establishment.css'
const EstablishmentDetails = ({ establishment }) => {

  return (
    <div className="visits-container">
      <h2>Detalles del Establecimiento</h2>
      <p><strong>Nombre:</strong> {establishment.name}</p>
      <p><strong>Dirección:</strong> {establishment.address}</p>
      <p><strong>Localidad:</strong> {establishment.city}</p>
      <p><strong>Provincia:</strong> {establishment.province}</p>
      <p><strong>Rubro:</strong> {establishment.industry}</p>
      <p><strong>Información de Contacto:</strong> {establishment.contactInfo}</p>
    </div>
  );
};

EstablishmentDetails.propTypes = {
  establishment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    province: PropTypes.string.isRequired,
    industry: PropTypes.string.isRequired,
    contactInfo: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired, // Asegurarse de que pase el ID del establecimiento
  }).isRequired,
};

export default EstablishmentDetails;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, NavLink, Routes, Route, useNavigate } from 'react-router-dom'; // Importa useNavigate para cambiar de ruta
import Sidebar from '../components/common/Sidebar';
import EstablishmentDetails from '../components/establishments/EstablishmentDetails';
import FormModal from '../components/forms/FormModal';
import FormsList from '../components/forms/FormsList';
import FormDetails from '../components/forms/FormDetails';
import VisitsInfoSection from '../components/visits/VisitsInfoSection';
import api from '../api/axiosConfig';
import '../components/forms/FormModal.css';
import './Establishment.css';

const Establishment = () => {
  const { id } = useParams();
  const [establishment, setEstablishment] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [forms, setForms] = useState([]);
  const [formToEdit, setFormToEdit] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null); // Estado para manejar el formulario seleccionado
  const isFetching = useRef(false);
  const navigate = useNavigate(); // Hook para redirigir entre rutas

  useEffect(() => {
    if (isFetching.current || id === establishment?.id) return;

    const fetchEstablishment = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await api.get(`/api/establishments/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setEstablishment(response.data);
        isFetching.current = true;
      } catch (error) {
        console.error('Error al obtener el establecimiento:', error);
        alert('Hubo un problema al obtener el establecimiento. Por favor, inténtelo de nuevo.');
      }
    };

    fetchEstablishment();
  }, [id, establishment?.id]);

  const fetchForms = useCallback(async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get(`/api/establishments/${id}/checklists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setForms(response.data);
    } catch (error) {
      console.error('Error al obtener los formularios:', error);
      alert('Hubo un problema al obtener los formularios. Por favor, inténtelo de nuevo.');
    }
  }, [id]);

  useEffect(() => {
    if (establishment) {
      fetchForms();
    }
  }, [establishment, fetchForms]);

  const handleAddFormClick = () => {
    setFormToEdit(null);
    setShowForm(true);
  };

  const handleEditForm = (form) => {
    setFormToEdit(form);
    setShowForm(true);
  };

  const handleDeleteForm = async (form) => {
    const confirmed = window.confirm(`¿Estás seguro de que quieres eliminar el formulario "${form.title}"? Esta acción no se puede deshacer.`);
    if (confirmed) {
      try {
        const token = localStorage.getItem('authToken');
        await api.delete(`/api/establishments/${id}/checklists/${form._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        alert('Formulario eliminado correctamente');
        fetchForms();
      } catch (error) {
        console.error('Error al eliminar el formulario:', error);
        alert('Hubo un problema al eliminar el formulario. Por favor, inténtelo de nuevo.');
      }
    }
  };

  const handleFormCreated = () => {
    setShowForm(false);
    fetchForms();
  };

  const closeModal = () => {
    setShowForm(false);
    setFormToEdit(null);
  };

  const handleViewForm = (form) => {
    setSelectedForm(form); // Selecciona el formulario y lo muestra
  };

  const handleBack = () => {
    setSelectedForm(null); // Vuelve a la lista de formularios
    navigate('checklists'); // Asegúrate de volver a la ruta de "checklists"
  };

  if (!establishment) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <nav className="tabs">
          <NavLink to="details" activeClassName="active-tab">Detalles del Establecimiento</NavLink>
          <NavLink
            to="checklists"
            activeClassName="active-tab"
            onClick={() => setSelectedForm(null)} // Reinicia el formulario seleccionado al hacer clic en "Formularios"
          >
            Formularios
          </NavLink>
        </nav>
        <Routes>
          <Route
            path="details"
            element={
              <div className="details-and-visits">
                <EstablishmentDetails establishment={establishment} />
                <VisitsInfoSection establishmentId={id} />
              </div>
            }
          />
          <Route
            path="checklists"
            element={
              selectedForm ? (
                <FormDetails form={selectedForm} onBack={handleBack} /> // Aquí se muestran los detalles del formulario
              ) : (
                <div>
                  <button className="add-button" onClick={handleAddFormClick}>
                    <span className="plus-icon">+</span> Agregar Formulario
                  </button>
                  {showForm && (
                    <FormModal
                      establishmentId={id}
                      onFormCreated={handleFormCreated}
                      onClose={closeModal}
                      formToEdit={formToEdit}
                    />
                  )}
                  <FormsList
                    forms={forms}
                    onView={handleViewForm} // Pasamos la función para manejar el click en Ver
                    onEdit={handleEditForm}
                    onDelete={handleDeleteForm}
                  />
                </div>
              )
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Establishment;

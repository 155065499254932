import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/axiosConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';

import showIcon from '../assets/show.png';
import hideIcon from '../assets/hide.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!email) {
      newErrors.email = 'El correo electrónico es obligatorio';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'El correo electrónico no es válido';
    }

    if (!password) {
      newErrors.password = 'La contraseña es obligatoria';
    } else if (password.length < 6) {
      newErrors.password = 'La contraseña debe tener al menos 6 caracteres';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await api.post('/api/users/login', { email, password });
        localStorage.setItem('authToken', response.data.token); // Guardar el token en localStorage

        // Hacer solicitud para obtener los datos del usuario
        const userResponse = await api.get('/api/users/profile', {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        });

        const userData = userResponse.data;
        localStorage.setItem('userName', userData.name); // Guardar el nombre del usuario

        toast.success(`Inicio de sesión exitoso! Bienvenido, ${userData.name}`, {
          position: 'top-right',
        });

        navigate('/dashboard');
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error('Credenciales incorrectas. Por favor, intenta nuevamente.', {
              position: 'top-right',
            });
          } else if (error.response.status === 500) {
            toast.error('Error del servidor. Inténtalo más tarde.', {
              position: 'top-right',
            });
          }
        } else {
          toast.error('Error de red. Por favor, verifica tu conexión.', {
            position: 'top-right',
          });
        }
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2>Iniciar Sesión</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Correo Electrónico</label>
            <input
              data-testid="emailInput"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={errors.email ? 'input-error' : ''}
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div className="password-container">
            <label>Contraseña</label>
            <div className="password-wrapper">
              <input
                data-testid="passwordInput"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={errors.password ? 'input-error' : ''}
              />
              <img
                src={showPassword ? hideIcon : showIcon}
                alt={showPassword ? 'Hide password' : 'Show password'}
                className="password-icon"
                onClick={togglePasswordVisibility}
              />
            </div>
            {errors.password && <p className="error">{errors.password}</p>}
          </div>
          <button data-testid="botonIniciarSesion" type="submit">Iniciar Sesión</button>
        </form>
        <p>¿No tienes una cuenta? <a data-testid="linkRegistrarse" href="/signup">Regístrate aquí.</a></p>
      </div>
    </div>
  );
};

export default Login;

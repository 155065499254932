import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../api/axiosConfig';
import './Signup.css';

// Importa las imágenes
import showIcon from '../assets/show.png';
import hideIcon from '../assets/hide.png';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [serverError, setServerError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'El nombre es obligatorio';
    }

    if (!email) {
      newErrors.email = 'El correo electrónico es obligatorio';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'El correo electrónico no es válido';
    }

    const passwordRequirements = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&+])[A-Za-z\d@$!%*?&+]{6,}$/;

    if (!password) {
      newErrors.password = 'La contraseña es obligatoria';
    } else if (!passwordRequirements.test(password)) {
      newErrors.password = 'La contraseña debe tener al menos 6 caracteres, incluyendo una letra, un número y un símbolo.';
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = 'La confirmación de la contraseña es obligatoria';
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Las contraseñas no coinciden';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await api.post('api/users/register', { name, email, password });
        localStorage.setItem('authToken', response.data.token);

        toast.success('¡Creación de cuenta exitosa!', {
          position: 'top-right',
          autoClose: 3000,
        });

        setTimeout(() => {
          navigate('/login');
        }, 3000);

      } catch (error) {
        if (error.response && error.response.status === 400 && error.response.data.message === 'User already exists') {
          toast.error('El usuario ya existe. Por favor, utiliza otro correo electrónico.', {
            position: 'top-right',
            autoClose: 4000,
          });
        } else {
          setServerError('Error en el registro o servidor no disponible');
        }
      }
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-card">
        <h2>Registrarse</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Nombre</label>
            <input
              data-testid="nameInput"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={errors.name ? 'input-error' : ''}
            />
            {errors.name && <p className="error">{errors.name}</p>}
          </div>
          <div>
            <label>Correo Electrónico</label>
            <input
              data-testid="emailInput"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={errors.email ? 'input-error' : ''}
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div>
            <label>Contraseña</label>
            <div className="password-container">
              <input
                data-testid="passwordInput"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={errors.password ? 'input-error' : ''}
              />
              <img
                src={showPassword ? hideIcon : showIcon}
                alt="Toggle Password Visibility"
                className="toggle-password"
                onClick={togglePasswordVisibility}
              />
            </div>
            {errors.password && <p className="error">{errors.password}</p>}
          </div>
          <div>
            <label>Confirmar Contraseña</label>
            <div className="password-container">
              <input
                data-testid="confirmPasswordInput"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={errors.confirmPassword ? 'input-error' : ''}
              />
              <img
                src={showConfirmPassword ? hideIcon : showIcon}
                alt="Toggle Confirm Password Visibility"
                className="toggle-password"
                onClick={toggleConfirmPasswordVisibility}
              />
            </div>
            {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
          </div>
          <button data-testid="botonRegistro" type="submit">Registrarse</button>
          {serverError && <p className="error">{serverError}</p>}
        </form>
        <p>¿Ya tienes una cuenta? <a href="/login">Inicia sesión aquí.</a></p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signup;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import api from '../../api/axiosConfig';
import './PaginatedForm.css';

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
  ({ theme }) => ({
    variants: [
      {
        props: { checked: true },
        style: {
          '.MuiFormControlLabel-label': {
            color: theme.palette.primary.main,
          },
        },
      },
    ],
  }),
);

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  value: PropTypes.any,
};

const PaginatedForm = ({ formJson, establishmentId, onClose, onFormCreated, formToEdit }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [responses, setResponses] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para el botón de "Guardar"

  // Crear referencias dinámicas para los campos
  const fieldRefs = useRef({});

  const fieldsPerPage = 5;
  const totalPages = formJson && formJson.fields ? Math.ceil(formJson.fields.length / fieldsPerPage) : 0;

  useEffect(() => {
    if (formToEdit && formToEdit.fields) {
      const initialResponses = {};
      formToEdit.fields.forEach(field => {
        initialResponses[field.name] = field.value || ''; // Prellena con el valor existente o una cadena vacía
      });
      setResponses(initialResponses);
    }
  }, [formToEdit]);

  const scrollToTop = () => {
    const formFields = document.querySelector('.form-fields');
    if (formFields) {
      formFields.scrollTop = 0;
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
    scrollToTop();
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages - 1);
    scrollToTop();
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const handleInputChange = (name, value, type) => {
    setIsDirty(true);

    if (type === 'file') {
      return;
    }

    setResponses({
      ...responses,
      [name]: value,
    });
    setFieldErrors({
      ...fieldErrors,
      [name]: false,
    });
  };

  const handleClose = () => {
    if (!isDirty) {
      onClose();
    } else if (window.confirm('Si cierras el formulario, se perderán los cambios no guardados. ¿Deseas continuar?')) {
      onClose();
    }
  };

  const handleSave = async () => {
    if (isSubmitting) return; // Prevenir múltiples envíos simultáneos

    setIsSubmitting(true);  // Deshabilitar el botón de "Guardar" durante el envío
    scrollToTop();

    const missingFields = formJson.fields.filter(field => field.required && !responses[field.name]);

    if (missingFields.length > 0) {
      const errors = {};
      let firstErrorPage = -1;

      missingFields.forEach(field => {
        errors[field.name] = true;
        const fieldIndex = formJson.fields.findIndex(f => f.name === field.name);
        const pageIndex = Math.floor(fieldIndex / fieldsPerPage);
        if (firstErrorPage === -1 || pageIndex < firstErrorPage) {
          firstErrorPage = pageIndex;
        }
      });

      setFieldErrors(errors);

      // Si hay errores, mover a la página donde está el primer error y hacer scroll hasta ese campo
      if (firstErrorPage !== -1) {
        setCurrentPage(firstErrorPage);
        setTimeout(() => {
          const firstErrorField = missingFields[0].name;
          if (fieldRefs.current[firstErrorField]) {
            fieldRefs.current[firstErrorField].scrollIntoView({ behavior: 'smooth' });
          }
        }, 0);
      }

      setIsSubmitting(false);  // Rehabilitar el botón de "Guardar" si hay errores
      return;
    }

    try {
      const token = localStorage.getItem('authToken');
      const apiUrl = formToEdit
        ? `/api/establishments/${establishmentId}/checklists/${formToEdit._id}`
        : `/api/establishments/${establishmentId}/checklist`;

      const method = formToEdit ? 'put' : 'post';

      await api[method](
        apiUrl,
        {
          title: formJson.title,
          description: formJson.description || "",
          fields: formJson.fields.map(field => ({
            label: field.label,
            name: field.name,
            value: responses[field.name] !== undefined ? responses[field.name] : (field.required ? '' : null),
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      alert('Formulario guardado con éxito');
      onFormCreated();
    } catch (error) {
      console.error('Error al guardar el formulario:', error);
      alert('Hubo un problema al guardar el formulario. Por favor, inténtelo de nuevo.');
    } finally {
      setIsSubmitting(false);  // Rehabilitar el botón de "Guardar" al finalizar
    }
  };

  if (!formJson || !formJson.fields) {
    return <p>No se puede cargar el formulario. Por favor, inténtalo de nuevo.</p>;
  }

  const startIndex = currentPage * fieldsPerPage;
  const fieldsToDisplay = formJson.fields.slice(startIndex, startIndex + fieldsPerPage);

  const renderPagination = () => {
    const pageButtons = [];
    const maxVisiblePages = 3;
    const halfVisible = Math.floor(maxVisiblePages / 2);

    let startPage = Math.max(0, currentPage - halfVisible);
    let endPage = Math.min(totalPages - 1, currentPage + halfVisible);

    if (endPage - startPage < maxVisiblePages - 1) {
      if (currentPage < halfVisible) {
        endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);
      } else if (currentPage > totalPages - halfVisible - 1) {
        startPage = Math.max(0, endPage - maxVisiblePages + 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={i === currentPage ? 'active' : ''}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <div className="paginated-form">
      <button className="close-button" onClick={handleClose}>X</button>
      <h2>{formJson.title}</h2>
      <div className="form-fields">
        {fieldsToDisplay.map((field, index) => (
          <div
            key={index}
            className={`form-group ${fieldErrors[field.name] ? 'error' : ''}`}
            ref={(el) => (fieldRefs.current[field.name] = el)} // Referencia dinámica
          >
            <label>{field.label}</label>
            {field.type === 'radio' ? (
              <RadioGroup
                name={field.name}
                value={responses[field.name] || ""}
                onChange={(e) => handleInputChange(field.name, e.target.value, field.type)}
              >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {field.options.map((option, i) => (
                    <MyFormControlLabel
                      key={i}
                      value={option}
                      label={option}
                      control={<Radio />}
                      style={{ marginRight: '20px' }} // Espacio entre opciones
                    />
                  ))}
                </div>
              </RadioGroup>
            ) : (
              <input
                type={field.type}
                name={field.name}
                placeholder={field.placeholder || ''}
                value={field.type === 'file' ? undefined : (responses[field.name] || "")}  // No controlar el valor del input si es de tipo "file"
                onChange={(e) => handleInputChange(field.name, e.target.value, field.type)}
              />
            )}
            {fieldErrors[field.name] && (
              <span className="error-text">Dato mandatorio</span>
            )}
          </div>
        ))}
      </div>
      <div className="pagination-container">
        <button onClick={handleFirstPage} disabled={currentPage === 0}>&laquo;</button>
        <button onClick={handlePreviousPage} disabled={currentPage === 0}>&lt;</button>
        {renderPagination()}
        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>&gt;</button>
        <button onClick={handleLastPage} disabled={currentPage === totalPages - 1}>&raquo;</button>
      </div>
      <button className="save-button" onClick={handleSave} disabled={isSubmitting}>Guardar</button>
    </div>
  );
};

PaginatedForm.propTypes = {
  formJson: PropTypes.object.isRequired,
  establishmentId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onFormCreated: PropTypes.func.isRequired,
  formToEdit: PropTypes.object, // Es opcional porque puede ser nulo si se está creando un nuevo formulario
};

export default PaginatedForm;
